.mainContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #233d5f;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  font-weight: bold;
}

.mainContainer:hover {
  background-color: #3D5779;
}

.mainContainer button {
  border-radius: 0;
}

.mainContainer button:hover {
  border-radius: 0;
  /** background-color: #FFB0F9 !important; */
}

.selected {
  background-color: #9c4a93 !important;
  color: white !important;
  background-image: none !important;
}

.scoreContainer {
  min-height: 17px;
}

.negativeScore {
  color: #F92C54;
}

.positiveScore {
  color: #2CF985;
}