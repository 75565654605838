@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, blue, lightBlue from colors;

.mainContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgb(0,28,68);
  background: linear-gradient(180deg, rgba(0,28,68,1) 0%, rgba(131, 201, 241, 0.021) 100%);
}

.searchContainer {
  display: flex;
  justify-content: center;
}

.loadsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ieErrorLayout {
  background: rgb(0,28,68);
  height: 100vh;
  text-align: center;
  color: white;
}

.ieErrorLayout > p {
  padding: 20px;
}