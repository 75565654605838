@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, purple, lightPurple from colors;

/**
loads: {
      table: {
        row: {
          width: 350
        },
        width: 365,
        padding: 10,
        column: {
          padding: 5,
          dateStartWidth: 75,
          equipmentWidth: 65,
          cityWidth: 120,
          stateWidth: 30,
          placeWidth: 160,
          rateWidth: 80,
          milesWidth: 90,
          companyWidth: 185
        }
      }
    }


*/

.truncateText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.smallText {
  font-size: 12px;
}

.textColorSubdued {
  color: #CCCCCC;
}

.locationSectionBackground {
  /* background-color: #17293f; */
}

.scoringSectionBackground {
  background-color: #1d324e;
}

.textColor {
  color: #FAFAFA;
}

.itemPadding {
  padding: 5px;
}

.masterGridTemplate {
  grid-template-columns: 35% 25% 17% 23%;
}

/** Main Grid */
.mainContainer {
  display: grid;
  composes: masterGridTemplate;
  grid-template-areas:
    "header header header header"
    "origin origin empty score"
    "dest dest miles score"
    "contact expected offer quote"
    "populateLoadSearch expectedRating offerRating quoteRating"
    "delivers delivers delivers details"
    "comments comments length weight"
    "comments comments loadCount stops";
  background-color: #1A2E47;
  align-self: center;
  width: 350px;
}

/** Load Header Grid **/
.topLoadHeaderRow {
  display: grid;
  grid-area: header;
  composes: masterGridTemplate;
  grid-template-areas: 
  "company company pickup date";
  background-color: #233D5F;
  font-weight: bold;
}

.companyNameItem {
  grid-area: company;
  composes: truncateText;
  composes: itemPadding;
  font-size: 13px;
}

.pickupLabel {
  grid-area: pickup;
  composes: textColorSubdued;
  composes: smallText;
  composes: itemPadding;
  text-align: center;
}

.pickupDate {
  grid-area: date;
  composes: itemPadding;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
}

.pickupDate > span {
  background-color: #9c4a93;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  color: white;
  margin-right: 2px;
}

/** End Load Header Grid */
.loadBody {
  background-color: #1A2E47;
}

.origin {
  grid-area: origin;
  composes: locationSectionBackground;
  composes: itemPadding;
}

.empty {
  grid-area: empty;
  composes: locationSectionBackground;
  composes: itemPadding;
}

.dest {
  grid-area: dest;
  composes: locationSectionBackground;
  composes: itemPadding;
}

.miles {
  grid-area: miles;
  composes: locationSectionBackground;
  composes: itemPadding;
}

.miles span {
  white-space: nowrap;
}

.score {
  grid-area: score;
  composes: itemPadding;
  composes: locationSectionBackground;
}

.contact {
  grid-area: contact;
  composes: itemPadding;
  composes: scoringSectionBackground;
}

.expected {
  grid-area: expected;
  composes: itemPadding;
  composes: scoringSectionBackground;
  display: flex;
  justify-content: center;
  user-select: none;
}

.offer {
  grid-area: offer;
  height: 100%;
  composes: itemPadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: end;
  composes: scoringSectionBackground;
  padding-top: 2px;
}

.offer input {
  text-align: right;
}

.quote {
  grid-area: quote;
  composes: itemPadding;
  composes: scoringSectionBackground;
}

.expectedRating {
  grid-area: expectedRating;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #1d324e;
  padding-bottom: 5px;

}

.offerRating {
  grid-area: offerRating;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #1d324e;
  padding-bottom: 5px;

}

.quoteRating {
  grid-area: quoteRating;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #1d324e;
  padding-bottom: 5px;
}

.ratingSpacer {
  grid-area: ratingSpacer;
  background-color: #1d324e;
}

.populateLoadSearch {
  grid-area: populateLoadSearch;
  text-align: center;
  composes: scoringSectionBackground;
  cursor: pointer;
}

.populateLoadSearch:hover {
   background-color: #374C68;
   border-radius: 7px;
}

.searchUpArrow {
  font-size: 14px;
  color: #2CF985;
}

.stars {

}

.delivers {
  grid-area: delivers;
  composes: itemPadding;
}

.details {
  grid-area: details;
  composes: itemPadding;
  display: flex;
  align-items: flex-end;
}


.destCityContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.destPodContainer {
  display: flex;
  justify-content: row;
  align-items: flex-start;
  /** color: #9C4A93;
  font-weight: bold; */
}

.scoreHighlight {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 2px;
  padding: 2px;
  font-size: 14px;
  width: 65px;
  border-radius: 10px;
  text-align: center;
  background-color: #233D5F;
}

.showDetailsLabel {
  color: #BC70B3;
  align-self: flex-end;
  cursor: pointer;
  text-align: center;
}

.phoneLink {
  color: #2DD6FB;
  font-size: 12px;
}

.planet {
  height: 62px;
  margin-left: 5px;
  width: auto;
}

.offerInputContainer {
  width: 65px;
  display: flex;
  justify-content: center;
}

.offerInputContainer {
  align-self: center;
}

.offerTextInput {
  width: 40px;
}

.calculatorImgContainer {
  align-self: center;
  padding-top: 3px;
}

.calculatorImg {
  height: 20px;
  width: auto;
  cursor: pointer;
}


