

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.loadListContainer {
  width: 350px;
}

.loadFilterControls {
  height: calc(100vh - 205px);
  width: 350px;
}

.scrollableActiveMarketsContainer {
  height: calc(100vh - 420px);
  overflow-y: auto;
  padding-bottom: 50px;
  padding-top: 10px;
}

.backToMarketsButton {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 0 !important;
  background-color: #9c4a93;
  color: whitesmoke;
  border: none;
  font-weight: bold;
}

.backToMarketsButton:hover {
  background-color: #B664AD;
}

.lighterBackground {
  padding-top: 10px;
  background-color: #233d5f;
  margin-bottom: 10px;
  margin-top: 5px;
}



.hide {
  display: none;
}