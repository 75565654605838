.mainContainer {
  display: flex;
  width: 60px;
  flex-direction: row;
  justify-content: space-between;
}



.starImg {
  height: 11px;
  width: auto;
}