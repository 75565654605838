.mainContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.mainContainer > div {
  align-self: center;
}

.loadingMessageText {
  padding-top: 20px;
  font-size: 15px;
}

.spinnerContainer {
  height: 120px;
}

.imageContainer {
  position: relative;
  top: -148px;
}

.imageContainer > img {
  height: 75px;
  width: auto;
}

.extraInfoContainer {

}

