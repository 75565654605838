.mainContainer {
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
  cursor: pointer;
}

.mainContainer:hover {
  background-color: #3D5779;
}

.negativeScore {
  color: #F92C54;
}

.positiveScore {
  color: #2CF985;
}