@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), local('RobotoMono'),
    url(../../fonts/RobotoMono-Regular.ttf) format('truetype');
}

.mainContainer {
  display: grid;
  grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
}

.mainContainer > div {
  text-align: center;
  align-self: flex-start;
}

.mainContainer button {
  font-family: 'Roboto Mono';
  outline: none;
}