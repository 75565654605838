@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, purple, lightPurple from colors;

.mainContainer {
  margin-top: 5px;
  background-color: #163052;
  /* padding-bottom: 5px; */
  width: 355px;
  row-gap: 3px;
  display: grid;
  grid-template-areas: "clearSearchButton cityStLabel settingsLabel studyLabel studyLabel"
                       "emptyLabel emptyInput settingsButton studyPicker studyPicker"
                       ". emptyInputPod emptyToOriginMilesLabel rangeLabel lohLabel"
                       "originLabel originInput emptyToOriginMiles originRangePicker lohPicker"
                       ". originInputPod ldedLabel expectedLabel quoteLabel"
                       "destLabel destInput origToDestMiles expectedSearchInput quoteSearchInput"
                       ". destInputPod loadedMilesInDays baselineLabel scoreLabel"
                       "searchButtonContainer searchButtonContainer calculatorButton baselineSearchInput scoreSearchInput"
                       "ratesLabelContainer ratesLabelContainer ratesLabelContainer ratesLabelContainer ratesLabelContainer";
}

.ratesLabelContainer {
  grid-area: ratesLabelContainer;
  row-gap: 3px;
  display: grid;
  grid-template-areas: "linehaulCpm fsurCpm rpbmCpm"
                       "marketLow marketMid marketHigh"
}

.centerVertically {
  align-self: center;
}

.mainContainer div .bp3-form-group {
  margin: 0 !important;
}

.mainContainer div {
  text-align: center;
}

.centeredText {
  text-align: center;
}

/** Grid Area Classes **/
.cityStLabel {
  grid-area: cityStLabel;
  composes: centeredText;
}

.settingsLabel {
  grid-area: settingsLabel;
  composes: centeredText;
}

.studyLabel {
  grid-area: studyLabel;
  composes: centeredText;
}

.emptyLabel {
  grid-area: emptyLabel;
  composes: centerVertically;
}

.emptyInput {
  grid-area: emptyInput;
}

.settingsButton {
  grid-area: settingsButton;
}

.studyPicker {
  grid-area: studyPicker;
}

.emptyToOriginMilesLabel {
  grid-area: emptyToOriginMilesLabel;
}

.rangeLabel {
  grid-area: rangeLabel;
}

.originRangePicker {
  grid-area: originRangePicker;
}

.lohPicker {
  grid-area: lohPicker;
}

.lohLabel {
  grid-area: lohLabel;
}

.ldedLabel {
  grid-area: ldedLabel;
}

.originLabel {
  grid-area: originLabel;
  composes: centerVertically;
}

.originInput {
  grid-area: originInput;
}

.emptyToOriginMiles {
  grid-area: emptyToOriginMiles;
}

.emptyToOriginMiles input,
.origToDestMiles input,
.expectedSearchInput input,
.quoteSearchInput input,
.scoreSearchInput input,
.baselineSearchInput input {
  width: 55px !important;
  text-align: right;
}

.originRangePicker {
  grid-area: originRangePicker;
}

.lohPicker {
  grid-area: lohPicker;
}

.destLabel {
  grid-area: destLabel;
  composes: centerVertically;
}

.destInput {
  grid-area: destInput;
}

.origToDestMiles {
  grid-area: origToDestMiles;
}

.destRangePicker {
  grid-area: destRangePicker;
}

.loadedMilesInDays {
  grid-area: loadedMilesInDays;
  display: flex;
  justify-content: center;
}

.expectedQuoteRow {
  grid-area: expectedQuoteRow;
  display: flex;
  justify-content: space-between;
}

.pickupDays {
  grid-area: pickupDays;
}

.deliveryDays {
  grid-area: deliveryDays;
}

.expectedLabel {
  grid-area: expectedLabel;
}

.quoteLabel {
  grid-area: quoteLabel;
  color: #2CF985;
  font-weight: bold;
}

.scoreLabel {
  grid-area: scoreLabel;
}

.searchButtonContainer {
  grid-area: searchButtonContainer;
}

.expectedSearchInput {
  grid-area: expectedSearchInput;
}

.quoteSearchInput {
  grid-area: quoteSearchInput;
}

.scoreSearchInput {
  grid-area: scoreSearchInput;
}

.quotesOnlyRadio {
  grid-area: quotesOnlyRadio;
}

.calcLabel {
  grid-area: calcLabel;
}

.calculatorButton {
  grid-area: calculatorButton;
  height: 25px; 
}

.baselineLabel {
  grid-area: baselineLabel;
}

.baselineSearchInput {
  grid-area: baselineSearchInput;
}

.emptyInputPod {
  grid-area: emptyInputPod;
}

.originInputPod {
  grid-area: originInputPod;
}

.destInputPod {
  grid-area: destInputPod;
}

.emptyInputPod,
.originInputPod,
.destInputPod {
  font-size: 11px;
  text-align: left !important;
}

.linehaulCpm {
  grid-area: linehaulCpm;
}

.fsurCpm {
  grid-area: fsurCpm;
}

.rpbmCpm {
  grid-area: rpbmCpm;
}

.marketLow {
  grid-area: marketLow;
}

.marketMid {
  grid-area: marketMid;
}

.marketHigh {
  grid-area: marketHigh;
}

.linehaulCpm,
.fsurCpm,
.rpbmCpm,
.marketLow,
.marketMid,
.marketHigh {
  font-size: 12px;
}

.clearSearchButton {
  grid-area: clearSearchButton;
}

.clearSearchButton button {
  border: none !important;
  cursor: pointer;
  color: #182026;
  background-color: #f5f8fa;
}

.calculatorButton img {
  width: 30px;
  height: auto;
  cursor: pointer;
}

.quotesOnlyRadio label {
  margin-bottom: 0px;
  font-size: 11px;
}


.quotesOnlyRadio > div > label:first-child {
  padding-left: 22px;
  margin-bottom: 3px;
}

.quotesOnlyRadio > div > label:not(:first-child) {
  padding-left: 0px;
}

/** End Grid Area Classes **/



.mainContainer label {
  font-weight: bold;
}

.mainContainer input {
  border-radius: 0;
}

.mainContainer button {
  border-radius: 0;
}

.searchButton {
  width: 90% !important;
  background-color: purple !important;
  color: white !important;
}

.settingsCog {
  font-size: 28px;
  cursor: pointer;
}

.powerLoadBoardCheck {
  font-size: 15px;
  color: #1F7CC1;
  margin-left: 5px;
}

.mainContainer button:disabled {
  background-color: #2c4c77 !important;
}

.mainContainer button {
  border-radius: 0;
  background-image: none !important;
}


.searchInputWithTopLabel {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


/**
  StudyPicker Styles
**/

.studyPicker span {
  white-space: nowrap;
}

.studyPicker > div > div {
  width: 100%;
}

.studyPicker button {
  width: 115px !important;
}

/**
  Temporary hiding of Empty text input!
*/ 


/* Update to reduce the padding from 10px to 5px */
.bp3-input {
  padding: 0 5px;
}
