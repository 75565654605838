@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor from colors;

@font-face {
  font-family: 'kimberley';
  src: local('kimberley'), local('kimberley'),
    url(../../fonts/kimberley-bl.ttf) format('truetype');
}
.windowContainer {
  composes: fullBrowserWindowContainer from "./Shared.module.css";
  background: linear-gradient(180deg, rgba(0,28,68,1) 0%, rgba(131,200,241,0.15) 100%);
  display: flex;
  justify-content: center;
  background-color: primaryBackgroundColor;
  color: primaryTextColor;
  overflow: hidden;
}

.mainBodyContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*  HIDE ADVERT UNTIL FIXED */
.advertLeft, .advertRight {
  display: none;
}

.advertLeft {
  width: 300px;
  font-family: kimberley;
  color: #88e2fb;
  text-align: center;
  font-size: 24px;
  align-self: center;
}

.advertLeft p {
  padding-top: 10px;
}

.advertLeftHeader {
  font-size: 28px;
}

.advertRight {
  width: 300px;
  font-family: kimberley;
  color: #88e2fb;
  font-size: 24px;
  align-self: center;
  padding-left: 100px;
}

.advertRight p {
  padding-top: 20px;
}

.mainLogoContainer {
  align-self: center;
  height: calc(100vh - 100px);
  min-height: 500px;
  position: relative;
}

.mainLogoGrid {
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-areas: "robot"
    "logo";
  height: 100%;
}

.loginControlsGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 26.66% 26.66% 26.66% 10%;
  grid-template-areas: ". title title title ."
                       ". titleBlurb titleBlurb titleBlurb ."
                       ". signIn . demo .";
  font-family: kimberley;
  color: #88e2fb;
}

.loginContainer {
  padding: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  width: 185px;
  background-color: #83c8f1;
  background: #4A7BBC;
  background: linear-gradient(180deg, #4A7BBC 20%, rgba(131,200,241,1) 100%);
}

.title {
  composes: fadeIn;
  grid-area: title;
  color: white;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  font-family: kimberley;
  color: #88e2fb;
}

.titleBlurb {
  composes: fadeIn;
  grid-area: titleBlurb;
  color: white;
  font-size: 18px;
  text-align: center;
  height: 42vh;
  min-height: 236px;
  font-weight: bold;
  font-family: kimberley;
  color: #88e2fb;
}

.loginContainer input {
  border-radius: 0 !important;
}

.loginContainer button {
  border-radius: 0 !important;
}

.loginContainer span {
  border-radius: 0 !important;
}

.robotImgContainer {
  grid-area: robot;
  composes: fadeIn;
  padding-top: 50px;
  animation-duration: 1s;
  animation-delay: 0.25s;
  text-align: center;
  height: 100%;
  width: fit-content;
}

.loadBuddyLogoContainer {
  grid-area: logo;
  text-align: center;
}

.loadBuddyLogo {
  composes: fadeIn;
  max-width: 250px;
  margin-top: -40px;
}

.robotImg {
  height: 100%
}

.textInput {
}

.demoButtonContainer {
  grid-area: demo;
  composes: fadeIn;
  animation-duration: 1s;
  animation-delay: 0.25s;
  cursor: pointer;
  text-align: center;
}

.demoButtonContainer:hover {
  color: white;
}

.signInButtonContainer {
  grid-area: signIn;
  composes: fadeIn;
  animation-duration: 1s;
  animation-delay: 0.25s;
  cursor: pointer;
  text-align: center;
}

.signInButtonContainer:hover {
  color: white;
}

.demoButtonContainer:hover {
  color: white;
}

.contactUsBanner {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #001c44;
  text-align: center;
  padding: 10px;
}

.contactUsLink {
  font-size: 18px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-family: kimberley;
  color: #88e2fb;
}

.contactUsDialog {
  width: auto !important;
}

.contactUsLink:hover {
  color: white;
}

.contactUsDialogBody {
  display: grid;
  row-gap: 10px;
  grid-template-columns: 120px auto;
  grid-template-areas: 
    "contactNameFirst contactDetailsFirst"
    "contactNameSecond contactDetailsSecond";
}

.contactName {
  font-weight: bold;
}

.contactDetails {
}

.contactNameFirst {
  grid-area: contactNameFirst;
}

.contactDetailsFirst {
  grid-area: contactDetailsFirst;
}

.contactNameSecond {
  grid-area: contactNameSecond;
}

.contactDetailsSecond {
  grid-area: contactDetailsSecond;
}

.loginTextContainer, .loginTextContainerBottom, .loginTextContainerTop {
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}

.loginTextContainerTop {
  margin-bottom: -10px;
}

.loginTextContainerBottom {
  margin-top: -10px;
}

.demoButtonImg {
  width: 70px;
  height: auto;
}

.jupiterButtonImg {
  margin-top: 10px;
  width: 48px;
  height: auto;
}

.button {
  width: 100%;
  background-color: #842075 !important;
  font-weight: bolder;
  color: white !important;
}

.rememberMeContainer {
  margin-top: -15px;
  display: flex;
  justify-content: flex-end;
}

.advertLeft {

}

.advertRight {

}

/*  Mobile Phones */
@media(max-width: 422px) {
  .mainLogoContainer {
    width: 100vw !important;
    height: auto;
  }
  .mainLogoGrid {
    min-height: 500px;
  }
  .robotImg {
    width: 100%;
  }
  .robotImgContainer {
    width: 100vw;
  }
  .advertLeft, .advertRight {
    display: none;
  }
}

@media(max-height: 649px) {
  .mainLogoContainer {
    position: fixed;
    top: 45px;
  }
}