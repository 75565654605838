.mainContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.label {
  font-size: 14px;
  padding-right: 5px;
  align-self: center;
  color: rgb(204,204,204);
}

.pod {
  text-align: start;
  padding-left: 5px;
}

.cityStateContainer {
  display: flex;
  align-self: center;
  font-size: bold;
  padding-left: 5px;
}

.cityContainer {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.state {
  padding-left: 5px;
}