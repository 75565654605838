@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor from colors;


.mainContainer {
  composes: loadBody from "./Load.module.css";
}

.comments {
  grid-area: comments;
  composes: itemPadding from "./Load.module.css";
  display: flex;
  justify-content: flex-start;
  background-color: #1d324e;
  padding: 10px;
}

.comments > div {
  align-self: center;
}

.equip {
  grid-area: details;
  composes: itemPadding from "./Load.module.css";
  display: flex;
  justify-content: center;
}

.equip > div {
  align-self: center;
}

.weight {
  grid-area: weight;
  composes: itemPadding from "./Load.module.css";
}

._length {
  grid-area: length;
  composes: itemPadding from "./Load.module.css";
}

.loadCount {
  grid-area: loadCount;
  composes: itemPadding from "./Load.module.css";
}

.stops {
  grid-area: stops;
  composes: itemPadding from "./Load.module.css";
}