@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, blue, lightBlue from colors;

.mainContainer {
  display: flex;
  flex-direction: column;
}

.userActivityRow {
  display: flex;
  padding: 5px;
}

.headerRow {
  display: flex;
  padding: 5px;
}

.userActivityRow:hover {
  background-color: #195380;
  cursor: pointer;
}

.carrierField {
  width: 80px;
}

.usernameField {
  width: 100px;
}

.timeStampField {
  display: flex;
  width: 150px;
}

.timeStampTimeAgo {
}

.timeStampRawText {
  
}

.headerText {
  font-weight: bold;
}

div {
  font-family: 'Roboto Mono';
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: yellow;
}

.onlineNow {
  color: yellow;
}

.hitsField {
  width: 50px;
  text-align: right;
}

@media only screen and (max-width: 600px) {
  .timeStampTimeAgo {
    display: none;
  }
  .timeStampRawText {
    width: auto;
    white-space: nowrap;
  }
}