@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, blue, lightBlue, lightPurple from colors;

.mainContainer {
  background-color: #13335E;
  display: flex;
  width: 100%;
}

.headerItemsContainer {
  display: flex;
  max-width: 450px;
  width: 100%;
  justify-content: space-between;
  padding: 7px;
}

.robot {
  height: 30px;
  width: auto;
}

.logoContainer {
  font-size: 22px;
  display: flex;
  justify-content: left;
}

.loadBuddyTextContainer {
  align-self: center;
}

.loadBuddyLogo {
  width: 128px;
  margin-bottom: -4px;
}

.robotContainer {
  align-self: center;
  height: 27px;
  margin-right: 5px;
}