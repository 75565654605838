@value colors: './Colors.module.css';
@value primaryBackgroundColor, primaryTextColor, purple, lightPurple from colors;

.mainContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.dateButtonsContainer {
  display: flex;
  justify-content: space-around;
  max-width: 400px;
}

.dateButton {
  border-radius: 0 !important;
}

.dateButton:disabled {
  background-color: #2c4c77 !important
}

.dateButtonSelected {
  composes: dateButton;
  color: white !important;
  background-color: purple !important;
  background-image: none !important;
}

.loadSourceContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2px;
  margin-top: -8px;
  margin-bottom: 8px;
}

.loadSourceContainer div {
  align-self: center;
}