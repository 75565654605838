.mainContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.leftRightPadding {
  padding-left: 5px;
  padding-right: 5px;
}

